export const company_status = {
  getAll: {
    url: "company-status/get",
    method: "GET",
    id: null,
    params: null,
  },
  createStatus: {
    url: "company-status/create",
    method: "POST",
    id: null,
    params: null,
  },
  editStatus: {
    url: "company-status/update/",
    method: "PUT",
    id: null,
    params: null,
  },
  delete: {
    url: "company-status/delete/",
    method: "DELETE",
    id: null,
  },
};
