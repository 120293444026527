<script>
import { company_status } from "@/config/api/company-status";

export default {
    props: ["selectedCompany"],

    mounted() {
        this.values = { ...this.selectedCompany };
    },
    data() {
        return {
            // colorA: null,
            color: null,
            title: "-",
            items: [
                {
                    text: "All Categories",
                    to: { path: `/categories` },
                },
                {
                    text: "Add",
                    active: true,
                },
            ],
            loading: false,
            values: {},
            schema: [
                {
                    index: 1,
                    type: "text",
                    name: "name",
                    label: "Company Name",
                    validation: "required",
                },
                {
                    index: 2,
                    type: "select",
                    name: "status",
                    label: "Status",
                    options: ["Active", "Hidden"],
                    //   placeholder: "Select a status",
                    //   "@change": (e) =>
                    //     e.target.value === "Fiji" ? alert("🏝") : alert("🤔"),
                    validation: "required",
                },
                {
                    index: 3,
                    type: "text",
                    name: "color",
                    label: "Color",
                    value: "#fffff",
                    validation: "required",
                },
            ],
            selectedColor: null,
        };
    },
    methods: {
        change(data) {
            function rgbToHexFunc(r, g, b) {
                const rgb = (r << 16) | (g << 8) | (b << 0);
                return "#" + (0x1000000 + rgb).toString(16).slice(1);
            }

            console.log("change");

            this.values.color = data;
            if (data.includes("rgb")) {
                let rgbArray = data.split(",");
                rgbArray[0] = rgbArray[0].substring(5, rgbArray[0].length);

                this.selectedColor = rgbToHexFunc(
                    rgbArray[0],
                    rgbArray[1],
                    rgbArray[2]
                ).replace(/\s/g, "");
            }
        },
        afterChange() {
            console.log("afterChange");
        },
        async submitHandler(data) {
            if (!data.color.includes("rgba")) {
                this.selectedColor = data.color;
            }

            if (this.selectedCompany) {
                this.updateStatus(data, this.selectedCompany._id);
            } else {
                this.addStatus(data);
            }
        },
        addStatus(data) {
            // this.loading = true;
            const api = company_status.createStatus;
            api.data = {
                name: data.name,
                // status: data.status,
                color: this.selectedColor,
            };
            console.log(api);
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Company status created successfully!", {
                        title: "Update",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                })
                .catch((err) => {
                    this.$bvToast.toast("Company status is not created!", {
                        title: "Update",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err.response.data);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("closeModal");
                });
        },
        updateStatus(data, id) {
            // this.loading = true;
            const api = company_status.editStatus;
            api.data = {
                name: data.name,
                status: data.status,
                color: this.selectedColor,
            };
            api.id = id;
            console.log(api);
            this.generateAPI(api)
                .then(() => {
                    this.$bvToast.toast("Company status updated successfully!", {
                        title: "Update",
                        variant: "success",
                        solid: true,
                    });
                    this.$emit("reloadData");
                })
                .catch((err) => {
                    this.$bvToast.toast("Company status is not updated!", {
                        title: "Update",
                        variant: "danger",
                        solid: true,
                    });
                    console.error(err.response.data);
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit("closeModal");
                    // this.$emit("reloadData");
                });
        },
    },
    computed: {
        checkTitle() {
            return this.selectedCompany != null
                ? "Edit Company Status"
                : "Add Company Status";
        },
    },
    watch: {
        selectedCompany(val) {
            if (val) {
                this.values = { ...val };
            }
        },
    },
};
</script>

<template>
    <b-modal id="modal-sheet" size="xl" @hidden="$emit('clearSelectedCompany')" :title="checkTitle" hide-footer>
        <FormulateForm name="CategoryAction" v-model="values" :schema="schema" @submit="submitHandler">
            <FormulateInput type="submit" class="float-right">
                <span v-if="loading">
                    <b-spinner class="vueformulate-loader"></b-spinner>
                    <span v-if="selectedCompany"> Updating... </span>
                    <span v-else> Adding... </span>
                </span>
                <span v-else>
                    <span v-if="selectedCompany"> Update </span>
                    <span v-else> Add </span>
                </span>
            </FormulateInput>

            <!-- <div v-if="selectedCompany"> -->
            <color-picker v-model="values.color" :position="{ left: 0, top: '40px' }" @change="change"
                @afterChange="afterChange" style="animation: glow 1s infinite alternate; border-radius: 50%">
            </color-picker>

        </FormulateForm>
    </b-modal>
</template>

<style>
.color-block .value,
.color-block .bg {
    border-radius: 50% !important;
}

.payment-duration .formulate-input-group {
    display: flex;
}

.payment-duration .formulate-input-group-item {
    margin: 8px;
}

.payment-duration .formulate-input-label {
    margin-top: -6px;
}

@media (min-width: 1200px) {
    .modal-dialog {
        max-width: 1650px !important;
    }
}

@media (min-width: 992px) {
    .modal-xl {
        max-width: 1200px !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 950px !important;
    }
}

@keyframes glow {
    from {
        box-shadow: 0 0 10px -10px #aef4af;
    }

    to {
        box-shadow: 0 0 10px 10px #aef4af;
    }
}
</style>
