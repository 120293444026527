<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { company_status } from "@/config/api/company-status";
import CompanyStatusModal from "@/components/company_status/company-status-modal";

export default {
    components: {
        Layout,
        PageHeader,
        CompanyStatusModal,
    },
    data() {
        return {
            title: "Company Status",
            items: [
                {
                    text: "All",
                },
                {
                    text: "Company Status",
                    active: true,
                },
            ],
            companyList: [],
            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: [],
            sortBy: "_id",
            sortDesc: false,
            fields: [
                { key: "name", sortable: true, label: "Name" },
                { key: "status", sortable: true, label: "Status" },
                { key: "actions", sortable: true, label: "Actions" },
            ],
            selectedCompany: null,
        };
    },
    computed: {
        /**
         * Total no. of records
         */
        rows() {
            return this.totalRows;
        },
    },
    mounted() {
        // Set the initial number of items
        this.loadData();
    },
    methods: {
        editStatus(data) {
            this.selectedCompany = data.item;
            this.$bvModal.show("modal-sheet");
        },
        hideActionModal() {
            this.$bvModal.hide("modal-sheet");
        },
        addStatus() {
            this.$bvModal.show("modal-sheet");
        },
        clearSelectedCompany() {
            this.selectedCompany = null;
        },
        /**
         * Search the table data with search input
         */
        onFiltered() {
            // Trigger pagination to update the number of buttons/pages due to filtering
            // this.totalRows = filteredItems.length;
            // this.currentPage = 1;
        },
        updatePage(page) {
            this.loadData(page);
        },
        deleteStatus(data) {
            this.$bvModal
                .msgBoxConfirm(`Please confirm that you want to delete this company status`, {
                    title: "Please Confirm",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "danger",
                    okTitle: "YES",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {

                        const api = company_status.delete;
                        api.id = data._id;
                        this.generateAPI(api)
                            .then(() => {
                                this.$bvToast.toast("Company Status deleted successfully!", {
                                    title: "Delete",
                                    variant: "success",
                                    solid: true,
                                });
                                this.loadData();

                            })
                            .catch((err) => {
                                this.$bvToast.toast("Company Status is not deleted", {
                                    title: "Delete",
                                    variant: "danger",
                                    solid: true,
                                });
                                console.error(err.response.data);
                            });
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        loadData() {
            this.companyList = [];
            const api = company_status.getAll;
            this.generateAPI(api)
                .then((res) => {
                    this.companyList = res.data.company_statuses;
                    this.totalRows = res.data.models.length;
                })
                .catch((err) => {
                    console.error(err.response.data);
                });
        },
    },
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0">
                        <b-button id="addProperty" @click="addStatus" pill variant="primary">Add Company Status
                        </b-button>

                        <b-tabs nav-class="nav-tabs-custom">
                            <b-tab title-link-class="p-3">
                                <template v-slot:title>
                                    <a class="font-weight-bold active">All Company Status</a>
                                </template>
                                <div class="row mt-4">
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_length" class="dataTables_length">
                                            <label class="d-inline-flex align-items-center">
                                                Show&nbsp;
                                                <b-form-select v-model="perPage" size="sm" :options="pageOptions">
                                                </b-form-select>&nbsp;entries
                                            </label>
                                        </div>
                                    </div>
                                    <!-- Search -->
                                    <div class="col-sm-12 col-md-6">
                                        <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                                            <label class="d-inline-flex align-items-center">
                                                Search:
                                                <b-form-input v-model="filter" type="search"
                                                    class="form-control form-control-sm ml-2"></b-form-input>
                                            </label>
                                        </div>
                                    </div>
                                    <!-- End search -->
                                </div>

                                <div class="table-responsive">
                                    <b-table class="table-centered" :items="companyList" :fields="fields"
                                        responsive="sm" :busy="$store.state.api.loading" :current-page="currentPage"
                                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                        :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                                        <template #table-busy>
                                            <div style="text-align: center" class="mt-5 mb-5">
                                                <pulse-loader color="#11657f" :loading="true"></pulse-loader>
                                            </div>
                                        </template>

                                        <template v-slot:cell(name)="row">
                                            <div class="badge font-size-12 status" :style="{
                                              backgroundColor: row.item.color,
                                            }">
                                                {{ row.value }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(status)="row">
                                            <div class="badge font-size-12" :style="{
                                              fontWeight: row.value == 'active' ? 'bold' : '100',
                                            }">
                                                {{ row.value.toUpperCase() }}
                                            </div>
                                        </template>
                                        <template v-slot:cell(actions)="row">
                                            <button class="action-btn" @click="editStatus(row)">
                                                <i class="ri-edit-line"></i>{{ row.value }}
                                            </button>
                                            <button class="action-btn" @click="deleteStatus(row.item)">
                                                <i class="ri-delete-bin-line"></i>{{ row.value }}
                                            </button>
                                        </template>
                                    </b-table>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="dataTables_paginate paging_simple_numbers float-right">
                                            <ul class="pagination pagination-rounded mb-0">
                                                <!-- pagination -->
                                                <b-pagination v-model="currentPage" :total-rows="rows"
                                                    :per-page="perPage" @change="updatePage"></b-pagination>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </b-tab>
                        </b-tabs>
                    </div>
                </div>
            </div>
        </div>
        <CompanyStatusModal :selectedCompany="selectedCompany" v-on:clearSelectedCompany="clearSelectedCompany"
            v-on:reloadData="loadData" v-on:closeModal="hideActionModal"></CompanyStatusModal>
    </Layout>
</template>

<style scoped>
#addProperty {
    float: right;
    margin-top: 10px;
}

.status {
    color: white;
    /* width: 5rem; */
    border: 1px solid black;
}
</style>
